import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import Content, { HTMLContent } from '../components/Content';

import Button from '../components/Button';
import PlayerContext from '../context/PlayerContext';
import ThemeContext from '../context/ThemeContext';
import HomePlayer from '../components/HomePlayer';

import BlogRoll from '../components/BlogRoll';
import Splash from '../components/Splash';

export const IndexPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <PlayerContext.Consumer>
      {(context) => (
        <ThemeContext.Consumer>
          {(theme) => (
            <div>
              <HomePlayer context={context}></HomePlayer>

              <Splash theme={theme}>
                <h1 className='title'>KUAA 99.9 FM</h1>
                <h2 className='sub-title'>{title}</h2>
                <PageContent content={content} />
              </Splash>
              <BlogRoll />
              <div className='callout'>
                <Button
                  onClick={() => {
                    navigate('/blog/');
                  }}
                  color='tertiary'
                >
                  More Blog Posts
                </Button>
              </div>
              {/* 
              {title} */}
            </div>
          )}
        </ThemeContext.Consumer>
      )}
    </PlayerContext.Consumer>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <IndexPageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
    />
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
