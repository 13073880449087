import { useEffect } from 'react';

const HomePlayer = (props) => {
  useEffect(() => {
    if (props.context.firstVisit) {
      props.context.setMiniPlayer(false);
      props.context.setFirstVisit(false);
    }
  }, [props.context]);

  return null;
};

export default HomePlayer;
