import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { navigate } from 'gatsby-link';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Tags from '../components/Tags';
import ButtonOutline from './ButtonOutline';
import ThemeContext from '../context/ThemeContext';
import SvgArrowRight from '../icons/ArrowRight';

var tinycolor = require('tinycolor2');

const BlogRollStyles = styled.div(
  (props) => `
    max-width: ${props.theme.size.base * 136}px;
    margin: 0 auto;
    padding: ${props.theme.size.base * 2}px;
    display: flex;
    flex-direction: column;
    gap: ${props.theme.size.base * 2}px;
    .blog-list-item {
      transition: opacity 450ms ease;
      display: flex;
      flex-grow: 1;
    }
  ${
    props.breakpoints.lg &&
    `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center;
    padding: ${props.theme.size.base * 2}px ${props.theme.size.base * 2}px ${
      props.theme.size.base * 2
    }px 0;
    .blog-list-item {
      width: calc(40% - ${props.theme.size.base * 4}px);
      max-width: 720px;
      &.is-featured {
        width: calc(60% - ${props.theme.size.base * 4}px);
        max-width: 720px;
      }
    }
  `
  };
  ${
    props.breakpoints.xl &&
    `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center;
    padding: ${props.theme.size.base * 4}px ${props.theme.size.base * 4}px ${
      props.theme.size.base * 4
    }px 0;
    gap: ${props.theme.size.base * 4}px;
    .blog-list-item {
      width: calc(40% - ${props.theme.size.base * 4}px);
      max-width: 720px;
      &.is-featured {
        width: calc(60% - ${props.theme.size.base * 4}px);
        max-width: 720px;
      }
    }
  `
  };
`
);

const BlogRoll = (props) => {
  const breakpoints = useBreakpoint();
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <BlogRollStyles theme={theme} breakpoints={breakpoints}>
          {posts &&
            posts.map(({ node: post }) => (
              <div
                key={post.id}
                className={`blog-list-item ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                {PostSummary(
                  post,
                  theme,
                  breakpoints,
                  post.frontmatter.featuredpost
                )}
              </div>
            ))}
        </BlogRollStyles>
      )}
    </ThemeContext.Consumer>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 10
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                tags
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      quality: 60
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);

const PostDetails = styled.article(
  (props) => `
  background: ${props.theme.color.background[80]};
  transition: background 450ms ease;
  display: flex;
  border-radius: ${props.theme.size.base * 2}px;
  .featured-thumbnail .gatsby-image-wrapper {
      border-radius:
       ${props.theme.size.base * 2}px ${props.theme.size.base * 2}px;
     overflow: hidden;
   }
  &:hover {
    background: ${props.theme.color.background[65]};
  }
  ^ > a {
    display: block;
  }
  .date {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    margin-bottom:  ${props.theme.size.base * 1.5}px;
    color: ${props.theme.color.foreground[50]};
    transition: color 1200ms ease;
  }
  .tags {
    margin-bottom:  ${props.theme.size.base}px;
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: ${props.theme.size.fontSize[6]};
    line-height: ${props.theme.size.lineHeight[6]};
    text-decoration: none;
    color: ${
      props.theme.dark
        ? props.theme.color.primary[60]
        : props.theme.color.primary[50]
    };
    transition: color 450ms ease;
    &:hover {
      color: ${
        props.theme.dark
          ? props.theme.color.secondary[70]
          : props.theme.color.secondary[60]
      };
    }
  }
  .excerpt {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[50]};
    transition: color 1200ms ease;
    margin-top:  ${props.theme.size.base * 2}px;
  }
  .continue-reading {
    margin-top:  ${props.theme.size.base * 2}px;
  }
  .summary {
    padding: ${props.theme.size.base * 4}px;
  }
  ${
    props.breakpoints.lg &&
    `border-radius: ${props.theme.size.base * 3}px ${
      props.theme.size.base * 3
    }px;
    .featured-thumbnail .gatsby-image-wrapper {
     border-radius: ${props.theme.size.base * 3}px ${
      props.theme.size.base * 3
    }px 0 0;
    }
    &.is-featured {
      .title {
        font-size: ${props.theme.size.fontSize[10]};
        line-height: ${props.theme.size.lineHeight[10]};
      }
      .summary {
        padding: ${props.theme.size.base * 8}px;
      }
    }
  `
  };
  ${
    props.breakpoints.xl &&
    `border-radius: ${props.theme.size.base * 4}px ${
      props.theme.size.base * 4
    }px;
    .featured-thumbnail .gatsby-image-wrapper {
     border-radius: ${props.theme.size.base * 4}px ${
      props.theme.size.base * 4
    }px 0 0;
    }
    &.is-featured {
      .title {
        font-size: ${props.theme.size.fontSize[10]};
        line-height: ${props.theme.size.lineHeight[10]};
      }
      .summary {
        padding: ${props.theme.size.base * 8}px;
      }
    }
  `
  };
`
);

function PostSummary(post, theme, breakpoints, isFeatured) {
  return (
    <PostDetails
      theme={theme}
      breakpoints={breakpoints}
      className={`${post.frontmatter.featuredpost ? 'is-featured' : ''}`}
    >
      <header>
        {post.frontmatter.featuredimage ? (
          <Link to={post.fields.slug} className='featured-thumbnail'>
            <PreviewCompatibleImage
              imageInfo={{
                image: post.frontmatter.featuredimage,
                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
              }}
            />
          </Link>
        ) : null}
        <div className='summary'>
          <div className='post-meta'>
            <div className='date'>{post.frontmatter.date}</div>
            {post.frontmatter.tags && post.frontmatter.tags.length ? (
              <div className='tags'>
                <Tags tags={post.frontmatter.tags}></Tags>
              </div>
            ) : null}
            <Link className='title' to={post.fields.slug}>
              {post.frontmatter.title}
            </Link>
          </div>
          <div className='continue-reading'>
            <ButtonOutline
              onClick={() => {
                navigate(post.fields.slug);
              }}
              size={-1}
              shape={4}
              color='tertiary'
            >
              Continue Reading{' '}
              <SvgArrowRight
                style={{ marginLeft: theme.size.base * 1 }}
              ></SvgArrowRight>
            </ButtonOutline>
          </div>
        </div>
      </header>
    </PostDetails>
  );
}
